import { useEffect, useRef } from 'react';
import MoreMenu from 'src/components/more-menu';
import { PlayButtonIconOnly } from 'src/components/play-button';
import SitePlayerLink from 'src/components/site-player-link';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { AudioListItemData } from 'src/types';
import { secondsToTimecode } from 'src/utilities/seconds-timecode';

const AudioListItem = ({ album, track }: AudioListItemData): JSX.Element => {
  const { trackNumber, id, title, duration, artistAndGroupDisplayInfo, composerDisplayInfo } = track;
  // hooks into the stream engine
  const { isPlaying, play, pause } = useVideoContext();
  // hooks into playback state
  const { currentActiveTrack } = usePlayback();
  // check if the current item is the one that is currently playing/paused in the player
  const isActive = currentActiveTrack?.id === id;
  // create a ref to the current item
  const itemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // scroll the active item into view automatically, when the list is displayed or updated
    if (isActive) {
      itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isActive]);

  return (
    <div className="flex items-center border-b border-divider py-3 pr-4 md:pr-0" ref={itemRef}>
      <div className="dg-text-regular-4-uc w-11 shrink-0 place-self-center text-center">
        {isActive ? <PlayButtonIconOnly playing={isPlaying} onPlay={play} onPause={pause} /> : trackNumber}
      </div>
      <SitePlayerLink
        mediaNode={album}
        trackId={id}
        className="grow rounded-sm outline-offset-2 focus-visible:focus-outline"
      >
        <div className="flex flex-col">
          {composerDisplayInfo && (
            <span className="dg-text-regular-4 text-white text-opacity-55">{composerDisplayInfo}</span>
          )}
          <span className="dg-text-regular-5">{title}</span>
          {artistAndGroupDisplayInfo && (
            <span className="dg-text-regular-3 text-white text-opacity-55">{artistAndGroupDisplayInfo}</span>
          )}
          <span className="dg-text-regular-4 text-white text-opacity-55">{secondsToTimecode(duration)}</span>
        </div>
      </SitePlayerLink>
      <MoreMenu node={track} />
    </div>
  );
};

export default AudioListItem;
