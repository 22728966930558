import CloseIcon from '@stageplus/icons/react/close';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ButtonIconOnly } from '../buttons/icon-button';
import { TextButton } from 'src/components/buttons/text-button';
import ControlledInputText from 'src/components/forms/elements/controlled-input-text';
import { useCouponCode, CouponCodeErrorType } from 'src/hooks/use-coupon-code';
import useTranslate from 'src/hooks/use-translate';
import { trackEvent } from 'src/tracking';
import { TranslationKeyCommon } from 'src/types';

type FormValues = {
  couponCode: string;
};

const couponCodeErrorTranslations: Record<
  (typeof CouponCodeErrorType)[keyof typeof CouponCodeErrorType],
  TranslationKeyCommon
> = {
  [CouponCodeErrorType.Invalid]: 'subscription_plans__form_error_coupon_invalid',
  [CouponCodeErrorType.Expired]: 'subscription_plans__form_error_coupon_expired',
};

/**
 * Renders a form to redeem a coupon code.
 * It uses the `useCouponCode` hook to handle the coupon code storage and validation.
 */
export default function CouponCodeForm() {
  const t = useTranslate();
  const { coupon, setCouponCode, resetCouponCode } = useCouponCode();
  const { control, handleSubmit, setValue, setError, formState } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      const coupon = await setCouponCode(values.couponCode);
      trackEvent({
        eventName: 'CouponRedeemed',
        coupon,
      });
      setValue('couponCode', '');
    } catch (error) {
      if (error instanceof Error && error.message in couponCodeErrorTranslations) {
        const message = t(couponCodeErrorTranslations[error.message as keyof typeof couponCodeErrorTranslations]);
        setError('couponCode', { message });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8 lg:mt-12" data-test="coupon-code-form">
      {/* Title */}
      <h3 className="dg-text-medium-3 mb-4 text-center">{t('subscription_plans__form_coupon_title')}</h3>
      {coupon ? (
        // Current Coupon Details
        <div className="flex flex-row items-center justify-center space-x-1">
          <span className="dg-text-regular-4-uc">{coupon.promotionCode}</span>
          <span className="dg-text-regular-4 opacity-70">
            {t('subscription_plans__form_coupon_percent_off', [coupon.percentOff])}
          </span>
          <ButtonIconOnly
            title={t('subscription_plans__form_clear_button_coupon')}
            icon={<CloseIcon />}
            onClick={resetCouponCode}
            className="size-6 rounded-full"
            dataTest="coupon-code-form-clear-button"
          />
        </div>
      ) : (
        // Coupon input field
        <div className="mx-auto flex flex-col gap-2 lg:w-4/5 lg:flex-row lg:items-start lg:justify-center">
          <div className="lg:flex-1">
            <ControlledInputText
              label={t('subscription_plans__form_input_coupon_label')}
              control={control}
              name="couponCode"
              defaultValue=""
              rules={{
                required: t('validation__required'),
              }}
            />
          </div>
          <div className="lg:w-52">
            <TextButton
              type="submit"
              loading={formState.isSubmitting}
              variation="secondary"
              disabled={!formState.isValid}
              fullWidth
            >
              {t('subscription_plans__form_submit_button_coupon')}
            </TextButton>
          </div>
        </div>
      )}
    </form>
  );
}
