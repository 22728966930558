import { useCallback, useEffect } from 'react';
import AttentionTwoToneIcon from '@stageplus/icons/react/attention-two-tone';
import CheckmarkTwoToneIcon from '@stageplus/icons/react/checkmark-two-tone';
import ErrorTwoToneIcon from '@stageplus/icons/react/error-two-tone';
import MenuCloseIcon from '@stageplus/icons/react/menu-close';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { RemoveScroll } from 'react-remove-scroll';
import { ButtonIconOnly } from 'src/components/buttons/icon-button';
import { TextButton } from 'src/components/buttons/text-button';
import TransientMessage from 'src/components/errors/transient-messages';
import styles from 'src/components/site-player-error.module.css';
import { exitFullScreen } from 'src/hooks/use-fullscreen';
import { PlaybackError } from 'src/hooks/use-streaming';
import useTranslate from 'src/hooks/use-translate';
import { useAuthModal, useIsAuthenticated } from 'src/state/auth';
import { usePlayback } from 'src/state/playback';
import { useImpressionTracking } from 'src/tracking';
import { isProductionHost } from 'src/utilities/url-helpers';

type SitePlayerErrorType = 'AuthError' | 'GeoError' | 'GenericError' | 'StreamFinished' | 'StreamAuthError';

const SitePlayerError = ({
  errorDisplayType,
  error,
}: {
  errorDisplayType: SitePlayerErrorType;
  error?: PlaybackError;
}) => {
  const t = useTranslate();

  // the error code that is used to track the error
  const errorCode = error?.code || 0;

  // the technical error message, which in some cases could be displayed to the user
  // so the user can report more info to the support team
  const errorLog = `${error?.errorType || ''}: ${error?.message || ''}`;

  const { reload: handleReload } = useRouter();
  // player queue operations
  const { clearQueue } = usePlayback();
  // opening auth modals
  const { open } = useAuthModal();
  // check if the user is logged in
  const isLoggedIn = useIsAuthenticated();

  // if we encounter an auth error, but the user is already logged in
  // there seems to a problem with the streaming authentication
  // a new login won't help, lets show a generic error message
  const normalizedErrorType = errorDisplayType === 'AuthError' && isLoggedIn ? 'StreamAuthError' : errorDisplayType;

  useEffect(() => {
    // if we encounter a stream authentication error on a non-production host, e.g. localhost or preview urls
    if (normalizedErrorType === 'StreamAuthError' && !isProductionHost(new URL(window.location.href))) {
      const isProduction = isProductionHost(new URL(window.location.href));
      if (isProduction) {
        // this should never happen, but just in case tell the advanced user to contact the support team
        console.warn(
          `Streaming service returned an authentication error. Please contact the support team support@stage-plus.com `,
        );
      } else {
        // warn a developer that the native HLS player will only work on testing domains
        // if they have disabled the cross-site tracking protection in Safari
        console.warn(
          `Note: The Native HLS player will only work on "${window.location.hostname}" if you disable "Settings > Privacy > Prevent cross-site tracking" in the Safari settings`,
        );
      }
    }
  }, [normalizedErrorType]);

  // track all player errors displayed to the user
  const { impressionObserverRef } = useImpressionTracking({ eventName: 'ErrorPageImpression', errorCode: errorCode });

  // open the login modal when the user clicks the login button
  const handleLogin = useCallback(() => {
    open('login');
  }, [open]);

  const handleClose = useCallback(async () => {
    // exit the full screen mode, just in case it is active
    exitFullScreen();
    // clear the playback queue, so the player is reset completely
    await clearQueue();
  }, [clearQueue]);

  const errorIcon = <ErrorTwoToneIcon className="inline-block size-20" />;
  const attentionIcon = <AttentionTwoToneIcon className="inline-block size-20" />;
  const checkMarkIcon = <CheckmarkTwoToneIcon className="inline-block size-20" />;

  const loginButton = (
    <TextButton onClick={handleLogin} variation="secondary" data-test="error-message-button-login">
      {t('player__error_auth_button')}
    </TextButton>
  );
  const closeButton = (
    <TextButton onClick={handleClose} variation="secondary" data-test="error-message-button-close">
      {t('player__error_geo_button')}
    </TextButton>
  );
  const reloadButton = (
    <TextButton onClick={handleReload} variation="secondary" data-test="error-message-button-reload">
      {t('player__error_generic_button')}
    </TextButton>
  );

  // @todo [2025-03-17] Use modal dialog to show this message to avoid focus jumping to the page behind
  return (
    <RemoveScroll enabled={true}>
      <div
        className={clsx('pointer-events-auto fixed inset-0 flex items-center justify-center', styles.errorgradientdown)}
        data-test="site-player-error"
        ref={impressionObserverRef}
      >
        {(() => {
          // a different error message is shown depending on the error type
          switch (normalizedErrorType) {
            case 'AuthError': {
              return (
                <TransientMessage
                  icon={errorIcon}
                  title={t('player__error_auth_title')}
                  message={t('player__error_auth_message')}
                  log={errorLog}
                  button={loginButton}
                />
              );
            }
            case 'StreamAuthError': {
              return (
                <TransientMessage
                  icon={errorIcon}
                  title={t('player__error_generic_title')}
                  message={t('player__error_generic_message')}
                  log={errorLog}
                  button={reloadButton}
                />
              );
            }
            case 'GeoError': {
              return (
                <TransientMessage
                  icon={attentionIcon}
                  title={t('player__error_geo_title')}
                  message={t('player__error_geo_message')}
                  button={closeButton}
                />
              );
            }
            case 'StreamFinished': {
              return (
                <TransientMessage
                  icon={checkMarkIcon}
                  title={t('player__error_stream_finished_title')}
                  message={t('player__error_stream_finished_message')}
                  button={closeButton}
                />
              );
            }
            default: {
              return (
                <TransientMessage
                  icon={errorIcon}
                  title={t('player__error_generic_title')}
                  message={t('player__error_generic_message')}
                  log={errorLog}
                  button={reloadButton}
                />
              );
            }
          }
        })()}

        <div className="absolute right-3 top-3 xl:right-4 xl:top-4">
          <ButtonIconOnly
            title={t('modal_close')}
            icon={<MenuCloseIcon />}
            data-test="site-player-error-close"
            className="rounded-full outline-offset-4"
            onClick={handleClose}
          />
        </div>
      </div>
    </RemoveScroll>
  );
};

export default SitePlayerError;
