import { useCallback, useEffect, useMemo } from 'react';
import { MenuItem } from '@headlessui/react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import clsx from 'clsx';
import Link from 'next/link';
import Translate from 'src/components/translate';
import useTranslate from 'src/hooks/use-translate';
import { toggleVideoTextTrack } from 'src/hooks/use-video-text-tracks';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { useSubtitleTracking, useAudioQualityTracking } from 'src/tracking/track-playback';
import { TranslationKeyCommon } from 'src/types';
import { canPlayDolbyAtmos } from 'src/utilities/media-capabilities';
import { AudioStreamQuality, useStreamFeatures } from 'src/utilities/streaming-helpers';

/** The labels of subtitle languages */
const subtitleLabelsMap: Record<string, TranslationKeyCommon> = {
  de: 'subtitle_language_de',
  en: 'subtitle_language_en',
  es: 'subtitle_language_es',
  fr: 'subtitle_language_fr',
  it: 'subtitle_language_it',
  ja: 'subtitle_language_ja',
  ko: 'subtitle_language_ko',
  pt: 'subtitle_language_pt',
  ru: 'subtitle_language_ru',
  zh: 'subtitle_language_zh',
};

/** The labels of audio quality levels */
const audioQualityLabelsMap: Record<AudioStreamQuality, TranslationKeyCommon> = {
  hd: 'mediaQuality__title_audio_high',
  sd: 'mediaQuality__title_audio_normal',
  stereogood: 'mediaQuality__title_audio_normal',
  stereohigh: 'mediaQuality__title_audio_high',
  lossless: 'mediaQuality__title_audio_lossless',
  atmos: 'mediaQuality__title_audio_atmos',
};

/** The link to the Dolby Atmos help page */
const dolbyInfoLink = 'https://help.stage-plus.com/hc/articles/4407627664283';

type SitePlayerSettingsMenuItemProps = {
  title: string;
  active?: boolean;
  onClick: () => void;
};

/**
 * A single Player Settings menu sub-heading
 * @component
 */
const SitePlayerSettingsMenuSubHeading = ({ children }: { children: React.ReactNode }) => {
  return <div className="dg-text-medium-8 select-none p-3 text-deepBlueC4">{children}</div>;
};

/**
 * A single Player Settings menu item
 * @component
 */
const SitePlayerSettingsMenuItem = ({ title, active, onClick, ...rest }: SitePlayerSettingsMenuItemProps) => {
  return (
    <MenuItem {...rest}>
      {({ focus }) => (
        <button
          className={clsx(
            'dg-text-regular-4 flex w-full items-center justify-between border-t border-divider p-3 pl-8 text-deepBlueC4 focus-visible:bg-buttonHoverGradient mouse:hover:bg-buttonHoverGradient',
            focus && 'bg-buttonHoverGradient',
          )}
          onClick={onClick}
        >
          <span>{title}</span>
          <span className={clsx(active ? 'opacity-100' : 'opacity-0')}>
            <CheckmarkIcon aria-hidden />
          </span>
        </button>
      )}
    </MenuItem>
  );
};

/**
 * get the name of the video quality level
 *  */
const getVideoLevelName = (level: { height: number; maxBitrate: number }) => {
  // @todo: convert the level name to a more readable il8n format once the levels list is defined
  return `h:${level.height} (${Math.floor(level.maxBitrate / 1000)}k)`;
};

type SitePlayerSettingsMenuProps = {
  // subtitle tracks
  textTracks: ReturnType<typeof useVideoContext>['textTracks'];
};

/**
 * A Player Settings menu that allows users to choose subtitles tracks, video quality and other additional playback features
 * @param {SitePlayerSettingsMenuProps} props
 * @param {ReturnType<typeof useVideoContext>['textTracks']} props.textTracks - the list of available subtitle tracks, if there are no subtitles available, the menu will not be displayed
 * @component
 */

export const SitePlayerSettingsMenu = ({ textTracks }: SitePlayerSettingsMenuProps) => {
  const t = useTranslate();
  const { levels, audio } = useVideoContext();
  //  check if the media has a Dolby Atmos stream
  const { currentMedia } = usePlayback();
  const { availableInDolbyAtmos } = useStreamFeatures(currentMedia);
  //  check if Dolby Atmos is supported by the browser
  const supportsDolbyAtmos = canPlayDolbyAtmos();
  // if the media has a Dolby Atmos stream, but the browser does not support it, display a note
  const shouldDisplayAtmosSupportNote = availableInDolbyAtmos && !supportsDolbyAtmos;
  // get the list of audio tracks
  // tracked audio quality labels are slightly different from the audio quality labels
  const trackedAudioQualityLabels = useMemo(() => {
    // get the list of audio quality labels,
    // note: only selectable audio tracks are included in the list
    const audioQualityLabels = audio?.audioTracks?.map((track) => track.label) || [];
    // if the media has a Dolby Atmos stream, but the browser does not support it, we display a note
    // which we track as a separate audio quality label in analytics
    if (audioQualityLabels.length > 0 && shouldDisplayAtmosSupportNote) {
      audioQualityLabels.push('atmos_unsupported');
    }
    return audioQualityLabels;
  }, [audio?.audioTracks, shouldDisplayAtmosSupportNote]);

  // by default the video qualities list is hidden
  // it can be toggled for debugging purposes via a query param in the url `&levels=true`
  const shouldDisplayQualityLevels = window.location.search.includes('levels=true');

  // track playback events in analytics
  const { trackSubtitleSelect, trackSubtitleOpen } = useSubtitleTracking();
  const { trackAudioQualityOpen, trackAudioQualitySelect } = useAudioQualityTracking();
  // get the current video quality level
  const currentLevel = levels?.currentVideoLevel;
  // get the current audio track
  const currentAudioTrack = audio?.currentAudioTrack;
  // what happens when user clicks on a particular subtitle
  const subtitleSelectHandler = useCallback(
    (selectedTrack: TextTrack) => {
      toggleVideoTextTrack({ selectedTrack, textTracks });
      // track the subtitle selection in analytics
      trackSubtitleSelect(
        selectedTrack.language,
        textTracks?.map((track) => track.language),
      );
    },
    [textTracks, trackSubtitleSelect],
  );

  // what happens when user clicks on a particular audio track
  const audioSelectHandler = useCallback(
    (trackLabel: string) => {
      // switch the audio track
      audio?.switchAudioTrack?.(trackLabel);
      // track the audio quality selection in analytics
      trackAudioQualitySelect(trackLabel, trackedAudioQualityLabels);
    },
    [audio, trackAudioQualitySelect, trackedAudioQualityLabels],
  );

  // this effect should trigger while opening of the settings menu
  useEffect(() => {
    // if there is a subtitle track that is currently showing, track it
    if (textTracks?.length > 0) {
      // track the subtitle menu opening in analytics
      const allLanguages = textTracks.map((track) => track.language);
      trackSubtitleOpen(allLanguages);
    }
    // if audio track list is available, track it
    if (trackedAudioQualityLabels.length > 0) {
      // track the audio quality menu opening in analytics
      trackAudioQualityOpen(trackedAudioQualityLabels);
    }
  }, [textTracks, trackAudioQualityOpen, trackSubtitleOpen, trackedAudioQualityLabels]);

  return (
    <>
      {/* display only if we actually have access to the multiple audio tracks */}
      {audio?.audioTracks && audio?.audioTracks.length > 0 && (
        <div>
          <SitePlayerSettingsMenuSubHeading>
            {t('component__audio_player_audio_formats_title')}
          </SitePlayerSettingsMenuSubHeading>
          <ul data-test="site-player-audio-quality-list">
            {audio.audioTracks.map((track, index) => (
              <li key={`audio-qualities-${index}`} aria-current={track.label === currentAudioTrack?.label}>
                <SitePlayerSettingsMenuItem
                  title={t(audioQualityLabelsMap[track.label as AudioStreamQuality])}
                  active={track.label === currentAudioTrack?.label}
                  onClick={() => audioSelectHandler(track.label)}
                />
              </li>
            ))}
            {/* When the media includes a Dolby Atmos track, we will explain/promote for the users who could use another option */}
            {shouldDisplayAtmosSupportNote && (
              <li
                key={`qualities-atmos-unsupported`}
                className="dg-text-regular-4 border-t border-divider p-3 pl-8 text-greyG2"
              >
                <span className="mb-2 flex w-full select-none items-center border-divider">
                  <span>{t('mediaQuality__title_audio_atmos')}</span>
                </span>
                <span className="block whitespace-pre-line">
                  <Translate
                    i18nKey="mediaQuality__atmos_unsupported"
                    components={{
                      1: <Link href={dolbyInfoLink} className="text-info underline" prefetch={false} target="_blank" />,
                      // make sure to render the break element
                      br: <br />,
                    }}
                  />
                </span>
              </li>
            )}
          </ul>
        </div>
      )}
      {/* display only if we actually have access to the multiple text tracks */}
      {!!textTracks?.length && (
        <div className="border-t border-divider">
          <SitePlayerSettingsMenuSubHeading>{t('player__settings_subtitles')}</SitePlayerSettingsMenuSubHeading>
          <ul data-test="site-player-subtitles">
            {[...(textTracks || [])].map((sub, index) => (
              <li key={`subtitles-${index}`} aria-current={sub.mode === 'showing'}>
                <SitePlayerSettingsMenuItem
                  title={t(subtitleLabelsMap[sub.language])}
                  active={sub.mode === 'showing'}
                  onClick={() => subtitleSelectHandler(sub)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* display only if required and we actually have access to the multiple video tracks */}
      {shouldDisplayQualityLevels && !!levels?.videoLevels?.length && (
        <div>
          <SitePlayerSettingsMenuSubHeading>{t('player__settings_quality')}</SitePlayerSettingsMenuSubHeading>
          <ul data-test="site-player-video-quality-list">
            {levels?.videoLevels.map((qualityLevel, index) => (
              <li
                key={`video-qualities-${index}`}
                aria-current={currentLevel && qualityLevel.uri === currentLevel?.uri}
              >
                <SitePlayerSettingsMenuItem
                  title={getVideoLevelName(qualityLevel)}
                  active={currentLevel && qualityLevel.uri === currentLevel?.uri}
                  onClick={() => levels?.switchLevel?.(index)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
