import { useMemo } from 'react';
import clsx from 'clsx';
import { CuePoint } from 'generated/graphql';
import { PerformanceWorkListItemProps } from 'src/components/performance-work/performance-work-list';
import SitePlayerLink from 'src/components/site-player-link';
import useVideoProgress from 'src/hooks/use-video-progress';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { secondsToTimecode } from 'src/utilities/seconds-timecode';

type CuePointsListProps = {
  active?: boolean;
  video: PerformanceWorkListItemProps['video'];
  performanceWorkId: string;
  disableClick?: boolean;
};

/**
 * Component displays details and a link to a Movement/CuePoint in a performance work.
 * Only used within a `PerformanceWorkListItem` component.
 */
const CuePointsListItem = ({
  cuePoint,
  active,
  video,
  performanceWorkId,
  disableClick,
}: { cuePoint: CuePoint } & CuePointsListProps) => {
  return (
    <SitePlayerLink
      mediaNode={video}
      trackId={performanceWorkId}
      timeSeconds={cuePoint.mark}
      role="listitem"
      // the player links are not clickable on live events
      disabled={disableClick}
      className="flex items-center rounded-sm py-3 outline-offset-2 focus-visible:focus-outline"
      data-test={active ? 'movement-link-active' : 'movement-link'}
    >
      <span
        className={clsx(
          'flex-1',
          // if the cue point is active, highlight it
          active ? 'dg-text-medium-7' : 'dg-text-regular-3',
        )}
      >
        {cuePoint.title}
      </span>
      <span className={clsx('dg-text-regular-4 text-white', !active && 'text-opacity-55')}>
        {secondsToTimecode(cuePoint.mark)}
      </span>
    </SitePlayerLink>
  );
};

/**
 * A list of Movements/CuePoints in a performance work.
 * Individual items are linked to the player. The currently active cue point is highlighted.
 */
export default function CuePointsList({
  video,
  performanceWorkId,
  cuePoints,
  disableClick,
}: { cuePoints: CuePoint[] } & CuePointsListProps) {
  const { videoRef } = useVideoContext();
  const { currentTime } = useVideoProgress(videoRef);
  const { currentActiveWork } = usePlayback();

  // find the currently active cue point
  const activeCuePoint = useMemo(() => {
    // if a different work is playing, there's no need to show the active cue point
    if (currentActiveWork?.id !== performanceWorkId) {
      return;
    }
    // find the cue point that is closest to the current time
    // @todo [next@>=16] Use `Array.findLast` once it is properly supported in Next.js (https://github.com/vercel/next.js/issues/66562)
    return cuePoints.filter((cuePoint) => cuePoint.mark <= currentTime).pop();
  }, [cuePoints, currentActiveWork?.id, currentTime, performanceWorkId]);

  return (
    <div role="list" className="mx-11 my-4 divide-y divide-divider border-t border-divider" data-test="movements-list">
      {cuePoints.map((cue, index) => (
        <CuePointsListItem
          key={index}
          cuePoint={cue}
          active={cue.mark === activeCuePoint?.mark}
          video={video}
          performanceWorkId={performanceWorkId}
          disableClick={disableClick}
        />
      ))}
    </div>
  );
}
