import { Fragment, useRef, PropsWithChildren } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import CloseIcon from '@stageplus/icons/react/close';
import clsx from 'clsx';
import { ButtonIconWithBorder } from 'src/components/buttons/icon-button';
import useTranslate from 'src/hooks/use-translate';

export type ModalProps = {
  children?: React.ReactNode;
  open?: boolean;
  wide?: boolean;
  onClose: (force?: boolean) => void;
  dataTest?: string;
};

/**
 * Modal overlay
 *
 * @component
 * @param children content of the modal
 * @param open display modal
 * @param onClose handle closing the modal
 */
export default function Modal({ children, open = false, onClose, dataTest, wide }: ModalProps) {
  const t = useTranslate();
  // lets have no visible focus element when the modal opens
  // the tabbing should work correctly though
  const noFocusRef = useRef(null);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-40" initialFocus={noFocusRef} static>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-overlay" aria-hidden="true" data-test="dialog-overlay" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto" data-test={dataTest}>
          <div className="flex min-h-full items-end justify-center md:items-center md:py-8">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="transform-gpu opacity-0 translate-y-20"
              enterTo="transform-gpu opacity-100 translate-y-0"
              leave="ease-in duration-100"
              leaveFrom="transform-gpu opacity-100 translate-y-0"
              leaveTo="transform-gpu opacity-0 translate-y-20"
            >
              <DialogPanel
                className={clsx(
                  'relative min-h-[calc(var(--vh)*100)] bg-mainBgBlueC2 md:min-h-0 md:rounded-xl',
                  wide ? 'w-full 2xl:w-[758px]' : 'w-full md:w-[535px]',
                )}
              >
                <div className="absolute left-auto right-0 top-0 z-40 flex justify-end p-3 md:absolute">
                  <ButtonIconWithBorder
                    title={t('modal_close')}
                    icon={<CloseIcon />}
                    data-test="modal-close"
                    onClick={() => onClose(true)}
                  />
                </div>
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

/**
 * Modal content wrapper to be used when you want the default padding around the modal content
 */
Modal.Content = function ModalContent({ children }: PropsWithChildren<unknown>) {
  return <div className="px-10 py-6 lg:px-16 lg:py-12">{children}</div>;
};
