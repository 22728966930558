import { useEffect, useRef, useState } from 'react';
import ChevronDownIcon from '@stageplus/icons/react/chevron-down';
import clsx from 'clsx';
import { LiveConcertFieldsFragment, VideoFieldsFragment, VodConcertFieldsFragment } from 'generated/graphql';
import { ButtonIconOnly } from 'src/components/buttons/icon-button';
import CommaSeparatedList from 'src/components/common/comma-separated-list';
import MoreMenu from 'src/components/more-menu';
import CuePointsList from 'src/components/performance-work/cuepoint-list';
import { PlayButtonIconOnly } from 'src/components/play-button';
import SitePlayerLink from 'src/components/site-player-link';
import useTranslate from 'src/hooks/use-translate';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { LivePerformanceWorkCompact, PerformanceWorkCompact } from 'src/types';
import { secondsToTimecode } from 'src/utilities/seconds-timecode';

type PerformanceWorkListProps = {
  performanceWorks?: (PerformanceWorkCompact | LivePerformanceWorkCompact)[];
  video: LiveConcertFieldsFragment | VodConcertFieldsFragment | VideoFieldsFragment;
};
export type PerformanceWorkListItemProps = {
  performanceWork: PerformanceWorkCompact | LivePerformanceWorkCompact;
  video: LiveConcertFieldsFragment | VodConcertFieldsFragment | VideoFieldsFragment;
  index: number;
};

/**
 * A PerformanceWorkListItem shows details about a PerformanceWork and its artists.
 * Only used within a PerformanceWorkList component.
 */
function PerformanceWorkListItem({ performanceWork, video, index }: PerformanceWorkListItemProps) {
  const t = useTranslate();

  // create a ref to the current item
  const itemRef = useRef<HTMLDivElement>(null);

  //  the currently active work from the player
  const { currentActiveWork } = usePlayback();

  // hooks into the stream engine
  const { isPlaying, play, pause } = useVideoContext();

  const {
    id: performanceWorkId,
    __typename,
    soloists,
    groups,
    work: { title, composers },
  } = performanceWork;
  const workDuration = 'duration' in performanceWork ? performanceWork.duration : 0;
  // check if the work is the one currently loaded and playing
  const isActive = currentActiveWork?.id === performanceWorkId;
  // live concert works are special, e.g. they are not linked to the player
  const isLiveConcertWork = __typename === 'LivePerformanceWork';

  // check if the work has any cue points
  const cuePoints = 'cuePoints' in performanceWork ? performanceWork.cuePoints : [];
  const hasCuePoints = cuePoints?.length > 0;
  // show the individual cues of a performance work
  const [isCueListOpen, setCueListOpen] = useState<boolean>(isActive);
  const handleDetailsToggle = () => {
    setCueListOpen((open) => !open);
  };

  useEffect(() => {
    // scroll the active item into view automatically, when the list is displayed or updated
    // NOTE: using `{behavior: smooth} option was causing unreliable e2e testing,
    // on some computers. We removed it intentionally.
    if (isActive) {
      itemRef.current?.scrollIntoView({ block: 'center' });
    }
  }, [isActive]);

  return (
    <div role="listitem" ref={itemRef}>
      <div className="flex select-none flex-row items-center py-3">
        <div className="dg-text-regular-4-uc flex w-11 shrink-0 justify-center place-self-center text-center">
          {isActive ? <PlayButtonIconOnly playing={isPlaying} onPlay={play} onPause={pause} /> : index + 1}
        </div>
        <SitePlayerLink
          className="flex-1 rounded-sm outline-offset-2 focus-visible:focus-outline"
          mediaNode={video}
          trackId={performanceWorkId}
          // the player links are not clickable on live events
          disabled={isLiveConcertWork}
          timeSeconds={0}
        >
          {composers && (
            <div>
              <CommaSeparatedList
                className="dg-text-regular-4 text-white text-opacity-55"
                list={composers}
                renderItem={(composer) => <span key={composer.id}>{composer.name}</span>}
              />
            </div>
          )}
          <div className="dg-text-regular-5">{title}</div>

          <ul className="dg-text-regular-3 text-white text-opacity-55">
            {soloists?.edges.map(({ node, role }, index) => (
              // we are using index as key here, because the artist id is not unique in this case
              <li key={index}>
                {node.name} ({role.displayName})
              </li>
            ))}
            {groups?.edges.map(({ node }, index) => (
              // we are using index as key here, because the group id is not unique in this case
              <li key={index}>
                {node.name} ({node.typeDisplayName})
              </li>
            ))}
          </ul>

          {Boolean(workDuration) && (
            <span className="dg-text-regular-4 text-white text-opacity-55">{secondsToTimecode(workDuration)}</span>
          )}
        </SitePlayerLink>
        <div className="flex justify-end">
          {hasCuePoints && (
            <ButtonIconOnly
              title={isCueListOpen ? t('icon__label_movements_hide') : t('icon__label_movements_show')}
              className={clsx(
                'size-11 transform-gpu rounded-full text-textSecondary transition duration-150 hover:bg-white/10 hover:text-brand-dg',
                isCueListOpen ? 'rotate-180' : 'rotate-0',
              )}
              icon={<ChevronDownIcon aria-hidden />}
              onClick={handleDetailsToggle}
            />
          )}
          {!isLiveConcertWork && <MoreMenu node={performanceWork} />}
        </div>
      </div>
      {hasCuePoints && isCueListOpen && (
        <CuePointsList video={video} performanceWorkId={performanceWorkId} cuePoints={cuePoints} />
      )}
    </div>
  );
}

/**
 * A PerformanceWorkList displays a list of PerformanceWorkEdges
 *
 * @example <PerformanceWorkList performanceWorks={performanceWorks} />;
 */
export default function PerformanceWorkList({ performanceWorks, video }: PerformanceWorkListProps) {
  if (!performanceWorks) return null;

  return (
    <div role="list" className="divide-y divide-divider border-y border-divider" data-test="performance-work-list">
      {performanceWorks.map((performanceWork, index) => {
        return (
          <PerformanceWorkListItem
            performanceWork={performanceWork}
            video={video}
            index={index}
            key={performanceWork.id}
          />
        );
      })}
    </div>
  );
}
